<template>
  <div style="text-align: left">
    <el-button
      type="primary"
      @click="showAddPackageModal"
    >Add A Day</el-button>

    <article>
      <section>
        <div class="safari_dialogs">
          <el-dialog
            :title="`Add A Safari Package Day`"
            :visible.sync="addPackageModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="isUploadingFile">
              <el-form
                :model="safariPackageDaysForm"
                :rules="packageRules"
                ref="safariPackageDaysForm"
                class="demo-safariPackageDaysForm2"
              >
                <div class="row">
                  <div class="col-md-3">
                    <label class="mt-3 mb-2">Day</label>
                    <el-form-item prop="day">
                      <el-input v-model="safariPackageDaysForm.day"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-9">
                    <label class="mt-3 mb-2">Main Destination</label>
                    <el-form-item prop="main_destination">
                      <el-input v-model="safariPackageDaysForm.main_destination"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Accommodation</label>
                    <el-form-item prop="accomodation">
                      <el-input v-model="safariPackageDaysForm.accomodation"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Meals</label>
                    <el-form-item prop="meals">
                      <el-input v-model="safariPackageDaysForm.meals"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="mt-3 mb-2">Description</label><br />
                    <el-form-item prop="description">
                      <el-input
                        type="textarea"
                        v-model="safariPackageDaysForm.description"
                        :rows="4"
                        placeholder="Please input"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">

                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Package Image</label><br />
                    <el-form-item>
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >

                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="addDay('safariPackageDaysForm')"
                  >Add Day</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2022 Ihamba, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addPackageModal: false,
      selectedFile: null,
      isUploadingFile: false,
      value: "",
      loading: false,
      loadingError: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      safariPackageDaysForm: {
        day: "",
        accomodation: "",
        description: "",
        main_destination: "",
        meals: "",
      },
      fileLists: [],

      packageRules: {
        day: [
          {
            required: true,
            message: "day is required",
            trigger: "blur",
          },
        ],
        accomodation: [
          {
            required: true,
            message: "accomodation is required",
            trigger: "blur",
          },
        ],
        meals: [
          {
            required: true,
            message: "meals is required",
            trigger: "blur",
          },
        ],
        main_destination: [
          {
            required: true,
            message: "main_destination is required",
            trigger: "change",
          },
        ],
        description: [
          {
            required: true,
            message: "description is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  props: {
    packageId: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleClose(done) {
      done();
    },

    handlePreview(file) {
      console.log(file.raw);
      this.fileLists = [file];
      this.selectedFile = file.raw;
    },
    handleRemove() {
      this.selectedFile = null;
    },

    async addDay(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploadingFile = true;
          if (this.selectedFile == null) {
            this.$notify({
              title: "Empty",
              message: "Profile picture is empty",
              type: "warning",
            });
          }

          try {
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("safari_tour_id", this.packageId);
            formData.append("day", this.safariPackageDaysForm.day);
            formData.append("image", this.selectedFile);
            formData.append(
              "accomodation",
              this.safariPackageDaysForm.accomodation
            );
            formData.append("meals", this.safariPackageDaysForm.meals);
            formData.append(
              "main_destination",
              this.safariPackageDaysForm.main_destination
            );
            formData.append(
              "description",
              this.safariPackageDaysForm.description
            );
            let request = await this.$http.post(
              `api/safari/package/day/add`,
              formData,
              {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              }
            );
            if (
              request.data.success &&
              request.data.message == "DAY_ADDED_SUCCESSFULLY"
            ) {
              this.$emit("refreshPackages");

              this.addPackageModal = false;
              this.$notify({
                title: "Success",
                message: "Package Day Added Successfully",
                type: "success",
              });
            } else if (request.data.message == "DAY_EXISTS") {
              this.$notify({
                title: "Duplicate",
                message: "Package Day Exists",
                type: "warning",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            this.$notify({
              title: "Upload Failed",
              message: "Unable to Add Package Day, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },

    showAddPackageModal() {
      this.addPackageModal = true;
      // this.safariPackageDaysForm.title = "";
      // this.safariPackageDaysForm.year_of_study = "";
      // this.safariPackageDaysForm.semester_of_study = "";
      // this.safariPackageDaysForm.credit_unit = "";
    },
  },
};
</script>

<style scoped>
.addBtn {
  background-color: green;
  color: white;
}
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.safari_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .safari_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .safari_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
