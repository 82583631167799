<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container">
          <div class="mb-3">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/safari-packages' }">Safari packages</el-breadcrumb-item>
              <el-breadcrumb-item>Days</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

          <h1
            class="mb-4"
            style="font-size: 1.1em; font-weight: 600;"
          >"{{ $route.params.packageTitle }}" Days:</h1>

          <div class="search_add_section">
            <div>
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Day"
                class="search_by_input"
                spellcheck="off"
              />
            </div>
            <div>
              <AddSafariPackageDayDialog
                @refreshPackages="getDays"
                :packageId="this.$route.params.packageId"
              />
            </div>
          </div>
          <hr />

          <div
            v-loading="loading || deleting"
            class="table-responsive"
          >
            <data-tables
              :data="days"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getDays"
                  v-else-if="!isLoading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Safari Package Days Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !loadingError">No Safari Package Days</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="50px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Package Day"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">

                  <img
                    :src="scope.row.featuring_photo"
                    style="width: 50px; height: 50px; object-fit: cover;"
                  >
                  <span> <strong> Day:</strong> {{ scope.row.day }} </span><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Tour Day Info"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span> <strong>Main Destination:</strong> {{ scope.row.main_destination }} </span><br />
                  <span><strong> accomodation:</strong> {{ scope.row.accomodation }} </span><br />
                  <span> <strong> meals:</strong> {{ scope.row.meals }}</span>
                  <!-- <span> <strong>Booking Date: </strong>{{ formattedDate(scope.row.booking_date) }} </span><br /> -->
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        @click="showEditPackageDialog(scope.row)"
                        type="warning"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        @click="deletePackage(scope.row.id)"
                        type="danger"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <EditSafariPackageDayDialog
      :isEditPackageDayDialogVisible="isEditPackageDayDialogVisible"
      :day="day"
      @closeEditPackageDialog="closeEditPackageDialog"
      @refreshPackages="getDays"
      :packageId="this.$route.params.packageId"
    />
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import AddSafariPackageDayDialog from "../components/dialogs/add-safari-package-day-dialog.vue";
import EditSafariPackageDayDialog from "../components/dialogs/edit-safari-package-day-dialog.vue";
import ScaleOut from "@/components/scale-out-component.vue";
export default {
  components: {
    AddSafariPackageDayDialog,
    EditSafariPackageDayDialog,
    ScaleOut,
  },

  data() {
    return {
      isLoading: false,
      loading: false,
      loadingError: false,
      deleting: false,
      isEditPackageDayDialogVisible: false,

      days: [],
      day: {},

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "room_name",
          value: "",
        },
      ],
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getDays();
  },

  methods: {
    showEditPackageDialog(day) {
      this.isEditPackageDayDialogVisible = true;
      this.day = day;
    },

    closeEditPackageDialog() {
      this.isEditPackageDayDialogVisible = false;
    },

    async getDays() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/package/days/${this.$route.params.packageId}`
        );
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.days = request.data.safari_tour_days;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Package Days now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async switchStatus(activation_status) {
      let staffStatus = {
        is_active: activation_status,
      };
      try {
        this.deleting = true;
        let request = await this.$http.patch(
          `staff/change_active`,
          staffStatus
        );
        if (
          request.data.success &&
          request.data.message == "STAFF_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
        ) {
          this.getStaff();
          this.showSuccessMessage("Success", "Staff status changed");
          this.deleting = false;
        } else if (
          request.data.message == "STAFF_ACTIVE_STATUS_COULD_NOT_BE_CHANGED"
        ) {
          this.showWarningMessage("Failed", "Staff status unable to change");
          this.deleting = false;
        } else if (request.data.message == "STAFF_ACTIVE_STATUS_IS_REQUIRED") {
          this.showWarningMessage("Failed", "Staff status required");
          this.deleting = false;
        } else if (
          request.data.message == "ONLY_APPROVED_STAFF_CAN_BE_ACTIVATED"
        ) {
          this.showWarningMessage(
            "Approve First",
            "Only Approved Staff Members can be activated/deactivated. Please approve them first."
          );
          this.deleting = false;
          console.log(this.is_active);
          // this.is_active = false;
        } else if (
          request.data.message == "ONLY_APPROVED_STAFF_MEMBERS_CAN_BE_ACTIVATED"
        ) {
          this.showWarningMessage(
            "Approve First",
            "Only Approved Staff Members can be activated/deactivated. Please approve them first."
          );
          this.deleting = false;
          console.log(this.is_active);
          // this.is_active = false;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.deleting = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to change staff status",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.deleting = false;
      }
    },

    async deletePackage(id) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this day. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(
              `api/safari/package/delete/${id}`
            );
            if (
              request.data.success &&
              request.data.message === "safari_tour deleted successfully"
            ) {
              this.getDays();
              this.$notify({
                title: "Success",
                message: "Package deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete Package, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

input:focus {
  border: 0.01em solid #409eff !important;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>